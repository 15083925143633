<script setup lang="ts">
import { computed } from 'vue'

export interface iBaseButtonProps {
  color?: 'blue' | 'red' | 'indigo'
  href?: string | null
  icon?: string | null
  iconClass?: string | null
  iconLeft?: string | null
  iconRight?: string | null
  loading?: boolean
  rounded?: boolean
  size?: 'xsIcon' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'base'
  tag?: string | null
  target?: string | null
  to?: string | null | object
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'white'
    | 'links'
    | 'transparent'
}

const props = withDefaults(defineProps<iBaseButtonProps>(), {
  color: 'indigo',
  href: null,
  icon: null,
  iconClass: null,
  iconLeft: null,
  iconRight: null,
  icons: null,
  loading: false,
  rounded: true,
  size: 'base',
  tag: null,
  target: null,
  to: null,
  variant: 'primary',
})

const sizeClasses = {
  xsIcon: {
    button: 'px-2 py-1.5 text-xs rounded',
    icon: 'h-4 w-4',
    iconLeft: '-ml-0.5 mr-1 h-4 w-4',
    iconRight: 'ml-2 -mr-0.5 h-4 w-4',
  },
  xs: {
    button: 'px-2.5 py-1.5 text-xs rounded',
    icon: 'h-4 w-4',
    iconLeft: '-ml-0.5 mr-1 h-4 w-4',
    iconRight: 'ml-2 -mr-0.5 h-4 w-4',
  },
  sm: {
    button: 'px-3 py-2 text-sm leading-4 rounded-md',
    icon: 'h-4 w-4',
    iconLeft: '-ml-0.5 mr-2 h-4 w-4',
    iconRight: 'ml-2 -mr-1 h-4 w-4',
  },
  base: {
    button: 'px-4 py-2 text-sm rounded-md',
    icon: 'h-5 w-5',
    iconLeft: '-ml-1 mr-2 h-5 w-5',
    iconRight: 'ml-3 -mr-1 h-5 w-5',
  },
  lg: {
    button: 'px-4 py-2 text-base rounded-md',
    icon: 'h-5 w-5',
    iconLeft: '-ml-1 mr-3 h-5 w-5',
    iconRight: 'ml-3 -mr-1 h-5 w-5',
  },
  xl: {
    button: 'px-6 py-3 text-base rounded-md',
    icon: 'h-5 w-5',
    iconLeft: '-ml-1 mr-3 h-5 w-5',
    iconRight: 'mr-3 -mr-1 h-5 w-5',
  },
}

const colors = {
  blue: 'blue',
  indigo: 'indigo',
  gray: 'gray',
  green: 'green',
}

const primaryColorClasses = {
  indigo: 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500',
  blue: 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500',
  red: 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
}

const variants = {
  primary() {
    return `text-white shadow-sm ${primaryColorClasses[props.color]}`
  },
  secondary() {
    return `text-${colorClass.value}-700 bg-${colorClass.value}-100 hover:bg-${colorClass.value}-200 shadow-sm`
  },
  outline() {
    return `border border-gray-300 hover:bg-gray-50 text-gray-700 focus:ring-${colorClass.value}-500 shadow-sm`
  },
  white() {
    return `border border-gray-300 hover:bg-gray-50 text-gray-700 focus:ring-white-500 bg-white shadow-sm`
  },
  link() {
    return `hover:bg-gray-50 text-gray-700`
  },
  transparent() {
    return `hover:bg-gray-50 text-gray-700`
  },
}

const buttonClass = computed(() => {
  return `
    disabled:opacity-75 disabled:cursor-not-allowed
    inline-flex items-center
    font-medium
    ${variantClass.value}
    ${sizeClassButton.value}
    ${roundedClass.value}
  `
})

const colorClass = computed(() => {
  return colors[props.color] || props.color
})

const roundedClass = computed(() => {
  if (!props.rounded) {
    return ''
  }

  return 'rounded-md'
})

const sizeClassIcon = computed(() => {
  return sizeClass.value.icon
})

const sizeClassIconLeft = computed(() => {
  return sizeClass.value.iconLeft
})

const sizeClassIconRight = computed(() => {
  return sizeClass.value.iconRight
})

const sizeClassButton = computed(() => {
  return sizeClass.value.button
})

const sizeClass = computed(() => {
  return sizeClasses[props.size] || {}
})

const tag = computed(() => {
  if (props.tag) {
    return props.tag
  }

  if (props.to) {
    return 'router-link'
  }

  if (props.href) {
    return 'a'
  }

  return 'button'
})

const variantClass = computed(() => {
  const variant = variants[props.variant]

  if (variant) {
    return variant.bind(this)()
  }

  return ''
})
</script>

<template>
  <component
    :is="tag"
    :class="buttonClass"
    :to="to"
    :href="href"
    :target="target"
    :disabled="loading || $attrs.disabled"
    v-bind="$attrs"
  >
    <BaseIcon
      v-if="iconLeft && !loading"
      :name="iconLeft"
      :class="[sizeClassIconLeft, iconClass]"
    />
    <BaseLoading
      v-if="loading"
      :class="sizeClassIconLeft"
      class="inline-block"
    />
    <slot>
      <BaseIcon v-if="icon" :name="icon" :class="[sizeClassIcon, iconClass]" />
    </slot>
    <BaseIcon
      v-if="iconRight && !loading"
      :name="iconRight"
      :class="sizeClassIconRight"
    />
  </component>
</template>
